import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShapes, faRunning, faUserEdit, faHeart } from '@fortawesome/free-solid-svg-icons'
import Layout from '@/components/layout'
import { SEO } from '@/components/seo'
import H3 from '@/components/H3'
import { img } from '@/tools/image'
import { PageProps } from 'gatsby'

const title = '療育案内'
const activities = [
  { title: '体操', description: '体操を通じて運動感覚を向上させ、脳や心の発達を目指します。' },
  { title: '工作', description: '紙を折る、ハサミで切る、のりを塗る、クレヨンを使うなど、手先の感覚を養います。' },
  { title: '音楽', description: '表現力・感受性の向上と心身の発達を目指します。' },
  { title: '読み聞かせ', description: '情緒の安定や想像力、認知面の発達を目指します。' },
  { title: '課外活動', description: '月数回ほど事業所の外で課外活動を行い、自然に触れるなどの経験させます。' },
]

const Program = (props: PageProps) => (
  <Layout title={title}>
    <SEO title={title} pathname={props.location.pathname} />
    <div className="text-md md:text-lg">
      <section className="playroom mb-10">
        <H3 text="児童発達支援" />
        <div className="flex flex-wrap mt-12 justify-center">
          <div className="w-full lg:w-1/2 px-4 text-center">
            <div className="text-2xl text-purple-300 p-3 w-14 h-14 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <FontAwesomeIcon icon={faShapes} />
            </div>
            <h6 className="text-xl mt-5 font-bold">遊びを通して</h6>
            <p className="mt-2 mb-4 text-left">
              知育玩具や絵本などの遊びを通して、一人一人ゆっくりと関わり成長を見守りながら、言葉・習慣を身に付けられるように支援します。
            </p>
          </div>
          <div className="w-full lg:w-1/2 px-4 text-center">
            <div className="text-2xl text-purple-300 p-3 w-14 h-14 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <h5 className="text-xl mt-5 font-bold">身体を動かす</h5>
            <p className="mt-2 mb-4 text-left">
              音楽やトランポリンなどを使い、リズム遊びをしたり、身体を動かしながら、他の児童と関わりをもったり、音への興味を持てるように支援します。
            </p>
          </div>
        </div>
        <div className="flex flex-wrap space-between mt-5">
          <div className="w-full md:w-1/2">
            <img className="object-cover h-auto w-full" src={img('handmade-hobbies1.png')} />
          </div>
        </div>
      </section>
      <section className="dayservice mb-10">
        <H3 text="放課後等デイサービス" />
        <div className="flex flex-wrap mt-12 justify-center">
          <div className="w-full lg:w-1/2 px-4 text-center">
            <div className="text-2xl text-purple-300 p-3 w-14 h-14 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <FontAwesomeIcon icon={faUserEdit} />
            </div>
            <h6 className="text-xl mt-5 font-bold">学校課題</h6>
            <p className="mt-2 mb-4 text-left">
              お子さん自身の地力を養い、学校の提出課題をやり切れるようにサポートいたします。
            </p>
            <img className="object-cover h-auto w-full mt-10 hidden md:block" src={img('handmade-hobbies2.png')} />
          </div>
          <div className="w-full lg:w-1/2 px-4 text-center">
            <div className="text-2xl text-purple-300 p-3 w-14 h-14 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <FontAwesomeIcon icon={faRunning} />
            </div>
            <h5 className="text-xl mt-5 mb-2 font-bold">各活動内容</h5>
            {activities.map((activity: { title: string; description: string }) => (
              <div className="flex mb-8 text-left" key={activity.title}>
                <div className="w-1/3 font-bold">{activity.title}</div>
                <div className="w-2/3">{activity.description}</div>
              </div>
            ))}
            <img className="object-cover h-auto w-full mt-10 block md:hidden" src={img('handmade-hobbies2.png')} />
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Program
